import { EV } from '@/api'

/**
 * Convert EV database 'charge_plug' to OCPI 'ConnectorType'
 * @param vehicle
 * @returns
 */
export function getConnectorType({
    vehicle,
    charge_plug,
}: {
    vehicle?: EV
    charge_plug?: string | null
}): string | undefined {
    const evdb_field = 'charge_plug'
    const evdb_value = charge_plug
        ? charge_plug
        : vehicle
        ? vehicle[evdb_field]
        : undefined

    if (!evdb_value) return undefined

    // evdb -> ocpi
    const mappings = {
        'Type 1': 'IEC_62196_T1', //Type 1 (Yazaki - SAE J1772), https://ev-database.org/img/informatie/Type-1-Yazaki-SAE-J1772.jpg
        'Type 2': 'IEC_62196_T2', //aka CCS2, Type 2 (Mennekes - IEC 62196) , https://ev-database.org/img/informatie/Type-2-Mennekes-IEC-62196.jpg
        Supercharger: 'IEC_62196_T2', // tesla specific, https://ev-database.org/img/informatie/Tesla-Supercharger.jpg
        CCS: 'IEC_62196_T2_COMBO', //Combined Charging System (CCS Combo 2), https://ev-database.org/img/informatie/Combined-Charging-System-CCS-Combo-2.jpg
        'CCS Supercharger': 'IEC_62196_T2_COMBO', // tesla specific
        CHAdeMO: 'CHAdeMO', //https://ev-database.org/img/informatie/CHAdeMO.jpg
    }

    return mappings[evdb_value]
}

export const EVDBChargePlugOptions = [
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 2', label: 'Type 2' },
    { value: 'Supercharger', label: 'Supercharger' },
    { value: 'CCS', label: 'CCS' },
    { value: 'CCS Supercharger', label: 'CCS Supercharger' },
    { value: 'CHAdeMO', label: 'CHAdeMO' },
]
