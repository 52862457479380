import React from 'react'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'

const routes: RouteDefinition[] = [
    {
        path: 'initial-search',
        component: React.lazy(
            () => import('@/modules/dashboard/pages/InitialSearchWizard')
        ),
        name: 'Initial Search Wizard',
    },
    {
        path: 'dashboard',
        component: React.lazy(
            () => import('@/modules/dashboard/pages/PriceDashboard')
        ),
        name: 'Price Dashboard',
    },
]
export const module = {
    name: 'Price Comparison',
    routes: routes,
    basePath: '',
} as ModuleConfig

export default module
