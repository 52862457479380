import OtpInput from 'react-otp-input'

interface OtpInputProps {
    value: string
    onChange: (code: string) => void
    numInputs?: number
    rest?: any
}

export function OTPInput({ value, onChange, numInputs, rest }: OtpInputProps) {
    return (
        <OtpInput
            value={value}
            onChange={onChange}
            numInputs={numInputs || 6}
            renderSeparator={
                <span className="flex-1 max-w-7 flex justify-center items-center">
                    -
                </span>
            }
            renderInput={(props) => (
                <div className="rounded-xl shadow h-10 w-12 flex justify-center items-center bg-gray-700 focus-within:ring ">
                    <input {...props} />
                </div>
            )}
            // inputType="number"
            inputStyle="border-0 border-none bg-gray-700 text-white "
            containerStyle="flex justify-center mt-1"
            {...rest}
        />
    )
}

export default OTPInput
