import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Navigate, Link, useLoaderData } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import FormErrors from '../../components/FormErrors'
import { allauth } from '../../core'

// UI
import { AuthPageWrapper } from '@/modules/auth/components/AuthPageWrapper'

export async function loader({ params }: { params: { key: string } }) {
    const key = params.key
    const resp = await allauth.getPasswordReset(key)
    return { key, keyResponse: resp }
}

export default function ResetPassword() {
    const { t } = useTranslation()
    const { key, keyResponse } = useLoaderData()
    const [response, setResponse] = useState({ fetching: false, content: null })

    const form = useForm<{ password1: string; password2: string }>()
    const formErrors = form.formState.errors

    function submit({
        password1,
        password2,
    }: {
        password1: string
        password2: string
    }) {
        setResponse({ ...response, fetching: true })
        allauth
            .resetPassword({ key, password: password1 })
            .then((resp) => {
                setResponse((r) => {
                    return { ...r, content: resp }
                })
            })
            .catch((e) => {
                console.error(e)
                window.alert(e)
            })
            .then(() => {
                setResponse((r) => {
                    return { ...r, fetching: false }
                })
            })
    }
    if ([200, 401].includes(response.content?.status)) {
        return <Navigate to="/account/login" />
    }
    let body
    if (keyResponse.status !== 200) {
        body = <FormErrors param="key" errors={keyResponse.errors} />
    } else if (response.content?.error?.detail?.key) {
        body = <FormErrors param="key" errors={response.content?.errors} />
    } else {
        body = (
            <form
                onSubmit={form.handleSubmit(submit)}
                className="flex flex-col gap-3"
            >
                <fieldset className="form-group">
                    <label htmlFor="password1" className="font-medium">
                        {t('account.password.label')}
                    </label>
                    <input
                        {...form.register('password1', {
                            required: t('account.required_field'),
                        })}
                        type="password"
                        placeholder={t('account.password.label')}
                        className="w-full input input-bordered input-primary"
                        id="password1"
                        name="password1"
                    />
                    {formErrors.password1 && (
                        <small className="text-error">
                            {formErrors.password1.message}
                        </small>
                    )}

                    <FormErrors
                        param="password"
                        errors={response.content?.errors}
                    />
                </fieldset>
                <fieldset className="form-group">
                    <label htmlFor="password2" className="font-medium">
                        {t('account.signup.password_again')}
                    </label>
                    <input
                        {...form.register('password2', {
                            required: t('account.required_field'),
                            validate: (value) =>
                                value === form.getValues('password1') ||
                                t('account.signup.passwords_must_match'),
                        })}
                        type="password"
                        placeholder={t('account.signup.password_again')}
                        className="w-full input input-bordered input-primary"
                        id="password2"
                        name="password2"
                    />
                    {formErrors.password2 && (
                        <small className="text-error">
                            {formErrors.password2.message}
                        </small>
                    )}

                    <FormErrors
                        param="password"
                        errors={response.content?.errors}
                    />
                </fieldset>

                <button
                    disabled={response.fetching}
                    type="submit"
                    className="mt-4 btn btn-primary"
                >
                    {t('account.login.submit')}
                </button>
            </form>
        )
    }

    return (
        <AuthPageWrapper loading={response.fetching}>
            <h1 className="text-xl">{t('account.password.reset_title')} </h1>
            {body}
            <p className="mt-4">
                <Link
                    to="/account/login"
                    className="link text-sm text-blue-900 font-medium"
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />

                    {t('account.password.to_login')}
                </Link>
            </p>
        </AuthPageWrapper>
    )
}
