import { useEffect, useRef } from 'react'
import { point, distance } from '@turf/turf'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

/**
 * Figure out if device is a mobile device
 */

const isMobile = () => {
    if (typeof window !== 'undefined') {
        return window.innerWidth <= 768
    }
    return false
}

/**
 * Checks if two objects are equal.
 * @param {Object} object1 - The first object.
 * @param {Object} object2 - The second object.
 * @returns {boolean} True if the objects are equal, false otherwise.
 * @example
 * const obj1 = { a: 1, b: 2 }
 * const obj2 = { a: 1, b: 2 }
 * const obj3 = { a: 1, b: 3 }
 * const obj4 = { a: 1, b: 2, c: 3 }
 * console.log(shallowEqual(obj1, obj2)) // true
 * console.log(shallowEqual(obj1, obj3)) // false
 * console.log(shallowEqual(obj1, obj4)) // false
 * console.log(shallowEqual(obj2, obj3)) // false
 * console.log(shallowEqual(obj2, obj4)) // false
 * console.log(shallowEqual(obj3, obj4)) // false
 * console.log(shallowEqual(obj1, null)) // false
 * console.log(shallowEqual(null, obj2)) // false
 * console.log(shallowEqual(null, null)) // false
 * console.log(shallowEqual(obj1, undefined)) // false
 * console.log(shallowEqual(undefined, obj2)) // false
 * console.log(shallowEqual(undefined, undefined)) // false
 * console.log(shallowEqual(obj1, {})) // false
 */
function shallowEqual(object1, object2) {
    // Check if objects are defined
    if (!object1 || !object2) return false

    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false
        }
    }

    return true
}

/**
 * Checks if a date is UTC or not.
 * @param {*} date
 * @returns
 */
function isUTCDate(date) {
    return date.getUTCHours() === date.getHours()
}

/**
 * Converts a UTC date to the corresponding date in Athens time.
 * @param {Date} date - The UTC date to be converted.
 * @returns {Date} The date in Athens time.
 * @example
 * const date = new Date('2021-01-01T12:34:56')
 * const athensDate = UTCtoAthensTime(date)
 * console.log(athensDate) // Fri Jan 01 2021 14:34:56 GMT+0200 (Eastern European Standard Time)
 * console.log(date) // Fri Jan 01 2021 12:34:56 GMT+0000 (Coordinated Universal Time)
 * console.log(date.getTime()) // 1609509296000
 */
const UTCtoAthensTime = (date) => {
    // Get the UTC time in milliseconds
    const utcTime = date.getTime()

    // Athens is UTC+2, so add 2 hours (2 * 60 * 60 * 1000 milliseconds) to the UTC time
    const athensTime = utcTime + 2 * 60 * 60 * 1000

    // Create a new Date object with the Athens time
    const athensDateObj = new Date(athensTime)

    return athensDateObj
}

const formatDate = (dateString) => {
    let date = new Date(dateString)
    if (isUTCDate(date)) {
        date = UTCtoAthensTime(date)
    }

    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        // dayPeriod: 'long',
    }
    return new Intl.DateTimeFormat('el-GR', options).format(date)
}

/**
 * Checks if a given datetime string corresponds to a datetime older than the specified duration.
 *
 * @param {string} dateString - The datetime string to be checked (e.g., '2023-01-01T12:34:56').
 * @param {Object} duration - An object specifying the duration threshold.
 *                            Keys: years, months, days, hours, minutes.
 * @returns {boolean} True if the datetime is older than the specified duration, false otherwise.
 */
const alertTimeElapsed = (dateString, duration) => {
    let date = new Date(dateString)
    if (isUTCDate(date)) {
        date = UTCtoAthensTime(date)
    }

    const currentDate = new Date()

    const timeDifference = currentDate - date
    const seconds = timeDifference / 1000
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = hours / 24
    const months = days / 30.44 // average month length
    const years = days / 365.25 // average year length

    return (
        years >= duration.years ||
        months >= duration.months ||
        days >= duration.days ||
        hours >= duration.hours ||
        minutes >= duration.minutes
    )
}

/**
 * Formats a date string to represent the time difference between the provided date
 * and the current date in a human-readable format.
 *
 * @param {string} dateString - The date string to be formatted (e.g., '2023-01-01T12:34:56').
 * @returns {string} A string representing the time difference, like 'πριν 1 ημέρα, 2 λεπτά'.
 */
const formatEditedBefore = (
    dateString: string,
    locale?: string | 'gr' | 'en'
) => {
    const currentDate = new Date()

    // if datestring is a string, convert it to a Date object
    let editedDate =
        typeof dateString === 'string' ? new Date(dateString) : dateString
    if (isUTCDate(editedDate)) {
        editedDate = UTCtoAthensTime(editedDate)
    }
    // typeof dateString === 'string'
    //     ? UTCtoAthensTime(new Date(dateString))
    //     : UTCtoAthensTime(dateString)

    const timeDifference = currentDate - editedDate

    const minutes = Math.floor(timeDifference / (1000 * 60))
    const hours = Math.floor(timeDifference / (1000 * 60 * 60))
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30.44)) // Average month length
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25)) // Average year length

    if (years > 0) {
        if (locale === 'en') {
            return `${years} ${years === 1 ? 'year' : 'years'} ago`
        }
        return `πριν ${years} ${years === 1 ? 'χρόνο' : 'χρόνια'}`
    } else if (months > 0) {
        if (locale === 'en') {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`
        }
        return `πριν ${months} ${months === 1 ? 'μήνα' : 'μήνες'}`
    } else if (days > 0) {
        if (locale === 'en') {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`
        }
        return `πριν ${days} ${days === 1 ? 'ημέρα' : 'ημέρες'}`
    } else if (hours > 0) {
        if (locale === 'en') {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
        }
        return `πριν ${hours} ${hours === 1 ? 'ώρα' : 'ώρες'}`
    } else {
        if (locale === 'en') {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
        }
        return `πριν ${minutes} ${minutes === 1 ? 'λεπτό' : 'λεπτά'}`
    }
}

/*
 * Formats a datetime string to represent the date and time in a human-readable format.
 *
 * @param {string} dateString - The datetime string to be formatted (e.g., '2023-01-01T12:34:56').
 * @returns {string} A string representing the date and time, like 'Τρίτη, 1 Ιανουαρίου 2023, 14:34'.
 */
const formatDateTime = (dateString?: string) => {
    if (!dateString) return ''

    let date = new Date(dateString)
    if (isUTCDate(date)) {
        date = UTCtoAthensTime(date)
    }
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }
    return new Intl.DateTimeFormat('el-GR', options).format(date)
}

/**
 *
 *  Get the screen size
 *
 */
function getScreenSize() {
    const screenSizes = {
        sm: window.matchMedia('(max-width: 639px)').matches,
        md: window.matchMedia('(min-width: 640px) and (max-width: 767px)')
            .matches,
        lg: window.matchMedia('(min-width: 768px) and (max-width: 1023px)')
            .matches,
        xl: window.matchMedia('(min-width: 1024px)').matches,
    }

    // Find the matching screen size
    const screenSize = Object.keys(screenSizes).find(
        (size) => screenSizes[size]
    )

    return screenSize || 'unknown'
}

/**
 *
 * Set the map style to a new one, but keep the layers that are not present in the new style
 *
 * @param {*} map
 * @param {*} newStyle
 */
function mapSetStyleDiff(map, newStyle) {
    map.setStyle({
        ...map.getStyle(),
        layers: map.getStyle().layers.map((layer) => {
            // if layer is present in the new style, keep the new style
            if (newStyle.layers.find((l) => l.id === layer.id)) {
                return newStyle.layers.find((l) => l.id === layer.id)
            }
            // if layer is not present in the new style, keep the old style
            return layer
        }),
    })
}

/**
 *
 * Compare names with a wildcard
 * example: compareNamesWithWildcard('john', 'j*') // true
 *
 * @param {*} name
 * @param {*} wildcard
 * @returns
 */
function compareNamesWithWildcard(name, wildcard) {
    const regex = new RegExp('^' + wildcard.split('*').join('.*') + '$', 'i')
    return regex.test(name)
}

/**
 *
 * Set the map style to a new one, but keep specific layers
 * method ref: https://maplibre.org/maplibre-gl-js/docs/API/classes/Map/#setstyle
 *
 * @param {*} map
 * @param {*} newStyle
 */
function mapSetStyleKeepLayers(map, newStyle) {
    if (!map || !newStyle || !map.getStyle()) return

    const keepLabels = ['maplibre-gl-directions-*', 'locations*']
    const layersToKeep = map.getStyle().layers.map((layer) => {
        return keepLabels.some((l) => compareNamesWithWildcard(layer.id, l))
            ? layer
            : null
    })

    map.setStyle(newStyle, {
        transformStyle: (previousStyle, nextStyle) => ({
            ...nextStyle,
            sources: previousStyle.sources, // sources don't change
            glyphs: previousStyle.glyphs, // glyphs don't change
            sprite: previousStyle.sprite, // sprite doesn't change
            layers: [
                ...nextStyle.layers,
                ...layersToKeep.filter((layer) => layer !== null),
            ],
        }),
    })
}

/**
 *
 * Trace component re-renders (hook)
 * ! Can only be used in a function component
 * ref: https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
 *
 *
 * @param {string} name - The name of the component.
 * @param {Object} props - The props of the component.
 *
 */
function useTraceUpdate(name, props) {
    const prev = useRef(props)
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v]
            }
            return ps
        }, {})
        // if (Object.keys(changedProps).length > 0) {
        //     console.log(`${name} changed props:`, changedProps)
        // }
        prev.current = props
    })
}

/**
 * Calculate distance between two points
 *
 * @param {*} lat1 : latitude of point 1
 * @param {*} lon1 : longitude of point 1
 * @param {*} lat2 : latitude of point 2
 * @param {*} lon2 : longitude of point 2
 * @returns
 */
function pointsDistance(lat1, lon1, lat2, lon2) {
    const point1 = point([lon1, lat1])
    const point2 = point([lon2, lat2])
    const options = { units: 'kilometers' }

    const result = distance(point1, point2, options)
    return Number(result.toFixed(2))
}

export {
    isMobile,
    shallowEqual,
    formatDate,
    formatDateTime,
    formatEditedBefore,
    alertTimeElapsed,
    getScreenSize,
    mapSetStyleDiff,
    mapSetStyleKeepLayers,
    useTraceUpdate,
    pointsDistance,
}
