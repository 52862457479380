import React from 'react'
import { useTranslation } from 'react-i18next'

function RaaeyLogo({ className, ...rest }) {
    const { i18n } = useTranslation()

    return (
        <img
            src={`/${import.meta.env.VITE_FRONTEND_ROOT}/img/raaey_logo_${
                i18n.language
            }.webp`}
            alt="RAAEY Logo"
            className={className}
        />
    )
}

export { RaaeyLogo }
