import React, { useState } from 'react'
import { cn } from '@/modules/core/lib/utils'
import { faCheckCircle, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { spawn } from 'child_process'

/**
 * Render text with a copy button on hover
 * @returns
 */
export function TextCopy({
    text,
    className,
}: {
    text: string
    className?: string
}) {
    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 5000)
    }

    return (
        <label
            className={cn(
                'group relative flex items-center text-sm text-gray-500 hover:text-blue-800 font-semibold bg-gray-100 p-1 pl-2 rounded-lg cursor-pointer transition-all',
                copied ? 'ring ring-green-600' : '',
                className
            )}
            onClick={handleCopy}
        >
            <span>{text}</span>
            <button
                className={cn(
                    ' transition-all absolute right-1 p-1 px-2 rounded-md text-xs',
                    copied
                        ? ' text-green-600'
                        : ' text-gray-500 opacity-0 group-hover:opacity-100'
                )}
                onClick={handleCopy}
            >
                {copied ? (
                    <span>
                        Copied <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                ) : (
                    <span>
                        Copy <FontAwesomeIcon icon={faCopy} />
                    </span>
                )}
            </button>
        </label>
    )
}

export default TextCopy
