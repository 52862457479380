import React from 'react'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'

const routes: RouteDefinition[] = [
    {
        path: 'statistics',
        component: React.lazy(() => import('./pages/Statistics')),
        name: 'App Statistics',
        authRoles: ['is_superuser', 'is_rae_admin'],
    },
    {
        path: 'statistics/:company_id',
        component: React.lazy(() => import('./pages/CompanyStatsDetails')),
        name: 'Company Statistics',
        authRoles: ['is_superuser', 'is_rae_admin'],
    },
]
export const module = {
    name: 'Admin',
    routes: routes,
    basePath: '',
    authRoles: ['is_superuser', 'is_rae_admin'],
} as ModuleConfig

export default module
