import React from 'react'
import { NavLink } from 'react-router-dom'

import ChargingCostLogo from '@/assets/logo_45.webp'

function Logo(props) {
    const { children, overwriteChildren, link, text, ...rest } = props

    return (
        <>
            <NavLink to={link} {...rest}>
                <h1
                    className="text-3xl flex flex-row items-center "
                    style={{
                        letterSpacing: '-2px',
                    }}
                >
                    {!overwriteChildren ? (
                        <>
                            <img
                                src={ChargingCostLogo}
                                alt="Charging Cost Logo"
                                className="h-7 inline"
                            />
                            <span className="">ChargingCost</span>
                        </>
                    ) : null}
                    {children}
                </h1>
            </NavLink>
        </>
    )
}

export default Logo
