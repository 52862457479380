import { useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as allauth from '../../core/lib/allauth'

// UI
import { AuthPageWrapper } from '@/modules/auth/components/AuthPageWrapper'

export async function loader() {
    const resp = await allauth.getRecoveryCodes()
    return { recoveryCodes: resp }
}

export default function RecoveryCodes() {
    const { t } = useTranslation()
    const { recoveryCodes } = useLoaderData()

    return (
        <AuthPageWrapper>
            <h1 className="text-xl">
                {t('account.mfa.recovery_codes.title')}{' '}
            </h1>

            {recoveryCodes.status === 200 ? (
                <>
                    <p>
                        {t('account.mfa.recovery_codes.instructions', {
                            available: recoveryCodes.data.unused_code_count,
                            total: recoveryCodes.data.total_code_count,
                        }).toString()}
                    </p>
                    <pre className="bg-gray-100 rounded-lg shadow-inner p-4">
                        {recoveryCodes.data.unused_codes.join('\n')}
                    </pre>
                </>
            ) : (
                <>
                    <p>{t('account.mfa.recovery_codes.no_recovery_codes')}</p>
                </>
            )}
        </AuthPageWrapper>
    )
}
