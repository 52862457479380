import { useEffect, createContext, useState } from 'react'
import { getAuth, getConfig } from './lib/allauth'
import type { AuthStatus, AllAuthEvent } from './types'
import type { ConfigurationResponse } from './api'

// Extended type: false means auth error
type AuthStatusFull = AuthStatus | false

interface AuthContextType {
    auth: AuthStatus
    config?: ConfigurationResponse
}

export const AuthContext = createContext<AuthContextType | null>(null)

function Loading() {
    return <div>Starting...</div>
}

function LoadingError() {
    return <div>Loading error!</div>
}

export function AuthContextProvider({
    children,
}: {
    children: React.ReactNode
}) {
    const [auth, setAuth] = useState<AuthStatusFull>(undefined)
    const [config, setConfig] = useState<ConfigurationResponse | undefined>(
        undefined
    )

    useEffect(() => {
        function onAuthChanged(e: Event) {
            const event = e as AllAuthEvent
            setAuth((auth: AuthStatusFull) => {
                if (typeof auth === 'undefined') {
                    console.log('Authentication status loaded')
                } else {
                    console.log('Authentication status updated')
                }
                return event.detail as unknown as AuthStatus
            })
        }

        document.addEventListener('allauth.auth.change', onAuthChanged)
        getAuth()
            .then((data) => setAuth(data))
            .catch((e) => {
                console.error(e)
                setAuth(false)
            })
        getConfig()
            .then((data) => setConfig(data))
            .catch((e) => {
                console.error(e)
            })
        return () => {
            document.removeEventListener('allauth.auth.change', onAuthChanged)
        }
    }, [])
    const loading = typeof auth === 'undefined' || config?.status !== 200
    return (
        // @ts-expect-error : if auth is false, the app is not rendered at all
        <AuthContext.Provider value={{ auth, config }}>
            {loading ? (
                <Loading />
            ) : auth === false ? (
                <LoadingError />
            ) : (
                children
            )}
        </AuthContext.Provider>
    )
}
