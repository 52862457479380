import React, { useContext, useState, useEffect } from 'react'
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { DataContext } from '@/modules/core/context/DataContext'
import { useTranslation } from 'react-i18next' // Import react-i18next
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useApi } from '@/modules/core'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CompanyOption = ({ company, selected, size = 'sm' }) => (
    <div className="flex items-center">
        <div className="col-span-3 flex items-center min-w-[45px]">
            {company.ProfileImage && (
                <img
                    src={`https://electrokinisi.yme.gov.gr/public/images/${company.ProfileImage}`}
                    alt="Profile"
                    className="mr-2 rounded"
                    width={45}
                />
            )}
        </div>
        <div className="flex items-center justify-start col-span-5 text-xs text-left">
            <p
                className={classNames(
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block '
                )}
            >
                {company.label}
            </p>
        </div>
    </div>
)

function CompanyFilter({ onSidebar = false, size = 'md', className, ...rest }) {
    const AuthenticatedAPI = useApi()
    const { params, setParam } = useContext(DataContext)
    const { t } = useTranslation() // Initialize the translation function

    const parameterValue = params.company // global state
    const [selectedCompany, setSelectedCompany] = useState(parameterValue || -1) // local state

    const {
        data: availableCompanies,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['availablecompanies'],
        queryFn: () => AuthenticatedAPI.api.apiListCompaniesList(),
        enabled: true,
        retry: 3,
    })

    useEffect(() => {
        if (parameterValue) {
            setSelectedCompany(parameterValue)
        } else {
            setSelectedCompany(-1)
        }
    }, [parameterValue])

    useEffect(() => {
        if (!selectedCompany || selectedCompany === -1) {
            setParam('company', null)
        } else {
            setParam('company', parseInt(selectedCompany))
        }
    }, [selectedCompany])

    const handleChange = (value) => {
        setSelectedCompany(value ? value.value : -1)
    }

    const companyOptions =
        availableCompanies?.map((company) => ({
            value: company.id,
            label: company.name,
            ProfileImage: company.ProfileImage,
        })) || []

    const selectedCompanyItem = companyOptions.find(
        (option) => option.value === selectedCompany
    ) || {
        value: -1,
        label: t('Όλες οι εταιρείες'),
    }

    return (
        <div className="my-3">
            {' '}
            <Listbox value={selectedCompanyItem} onChange={handleChange}>
                {({ open }) => (
                    <>
                        <label
                            className={`text-${size}`}
                            for="open-company-filter"
                        >
                            &nbsp; {t('Φορέας Εκμετάλλευσης')} &nbsp;
                            {/* Use t() to translate the label */}
                        </label>
                        <div className="relative">
                            <ListboxButton
                                id="open-company-filter"
                                className={`select select-${size} relative w-full  cursor-pointer rounded-md bg-white pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-gray-300 sm:text-sm sm:leading-6`}
                            >
                                <span className="flex items-center w-full h-full">
                                    {selectedCompanyItem ? (
                                        <div className="flex items-center overflow-x-hidden overflow-hidden w-full">
                                            {selectedCompanyItem.ProfileImage && (
                                                <img
                                                    src={`https://electrokinisi.yme.gov.gr/public/images/${selectedCompanyItem.ProfileImage}`}
                                                    alt="Company logo"
                                                    className="w-8 rounded-full mr-3"
                                                />
                                            )}
                                            <span className={`block truncate `}>
                                                {selectedCompanyItem.label}
                                            </span>
                                        </div>
                                    ) : (
                                        t('Όλες οι εταιρείες')
                                    )}
                                </span>

                                {/* <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span> */}
                            </ListboxButton>
                            <Transition
                                show={open}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption
                                        key={-1}
                                        value={{
                                            value: -1,
                                            label: t('Όλες οι εταιρείες'),
                                        }}
                                        className={({ focus }) =>
                                            classNames(
                                                focus
                                                    ? 'bg-gray-300 text-gray-900'
                                                    : '',
                                                !focus ? 'text-gray-900' : '',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                    >
                                        {({ selected, focus }) => (
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                        'ml-3 block truncate'
                                                    )}
                                                >
                                                    {t('Όλες οι εταιρείες')}
                                                </span>
                                                {selected ? (
                                                    <span className="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        )}
                                    </ListboxOption>
                                    {companyOptions &&
                                        companyOptions.map((company) => (
                                            <ListboxOption
                                                key={company.value}
                                                className={({ focus }) =>
                                                    classNames(
                                                        focus
                                                            ? 'bg-gray-300 text-gray-900'
                                                            : '',
                                                        !focus
                                                            ? 'text-gray-900'
                                                            : '',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={company}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <CompanyOption
                                                            company={company}
                                                            selected={selected}
                                                        />
                                                        {selected ? (
                                                            <span className="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCheck
                                                                    }
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </ListboxOption>
                                        ))}
                                </ListboxOptions>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}

export default CompanyFilter
