import { Navigate, useLocation } from 'react-router-dom'
import {
    useAuthChange,
    AuthChangeEvent,
    useAuthStatus,
    useRoles,
} from './hooks.ts'
import { Flows, AuthenticatorType } from './lib/allauth.ts'
import type { AllauthFlow } from './types.d.ts'
import type { AuthenticationResponse } from './api'

export const URLs = Object.freeze({
    LOGIN_URL: '/account/login',
    LOGIN_REDIRECT_URL: '/dashboard',
    LOGOUT_REDIRECT_URL: '/',
    TOTP_SETUP_URL: '/account/2fa/totp/activate',
})

const flow2path: Record<string, string> = {}
flow2path[Flows.LOGIN] = '/account/login'
flow2path[Flows.LOGIN_BY_CODE] = '/account/login/code/confirm'
flow2path[Flows.SIGNUP] = '/account/signup'
flow2path[Flows.VERIFY_EMAIL] = '/account/verify-email'
flow2path[Flows.PROVIDER_SIGNUP] = '/account/provider/signup'
flow2path[Flows.REAUTHENTICATE] = '/account/reauthenticate'
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.TOTP}`] =
    '/account/authenticate/totp'
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.RECOVERY_CODES}`] =
    '/account/authenticate/recovery-codes'
flow2path[`${Flows.MFA_AUTHENTICATE}:${AuthenticatorType.WEBAUTHN}`] =
    '/account/authenticate/webauthn'
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.TOTP}`] =
    '/account/reauthenticate/totp'
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.RECOVERY_CODES}`] =
    '/account/reauthenticate/recovery-codes'
flow2path[`${Flows.MFA_REAUTHENTICATE}:${AuthenticatorType.WEBAUTHN}`] =
    '/account/reauthenticate/webauthn'
flow2path[Flows.MFA_WEBAUTHN_SIGNUP] = '/account/signup/passkey/create'

/**
 * From a list of 2FA authenticator types, pick the preferred one (TOTP)
 * @param flows
 */
function preferedAuthenticator(flow_types: string[]) {
    return (
        flow_types.find((type) => type === AuthenticatorType.TOTP) ??
        flow_types[0]
    )
}

/**
 * Returns the path for a given authentication flow.
 */
export function pathForFlow(flow: AllauthFlow, typ?: string) {
    let key = flow.id
    if (typeof flow.types !== 'undefined') {
        typ = typ ?? preferedAuthenticator(flow.types) //flow.types[0]
        key = `${key}:${typ}` as AllauthFlow['id']
    }
    const path = flow2path[key] ?? flow2path[flow.id]
    if (!path) {
        throw new Error(`Unknown path for flow: ${flow.id}`)
    }
    return path
}

export function pathForPendingFlow(auth: AuthenticationResponse) {
    const flow = auth.data.flows.find((flow) => flow.is_pending)
    if (flow) {
        return pathForFlow(flow)
    }
    return null
}

function navigateToPendingFlow(auth: AuthenticationResponse) {
    const path = pathForPendingFlow(auth)
    if (path) {
        return <Navigate to={path} />
    }
    return null
}

/**
 * Checks if the user has the required roles. Roles are not inclusive.
 * If the user has at least one of the required roles, it will return true.
 *
 * @param userRoles: The roles of the user.
 * @param requiredRoles: The roles required to access the resource.
 * @returns
 */
function hasRole(userRoles: string[], requiredRoles: string[]) {
    return userRoles.some((role) => requiredRoles.includes(role))
}

/**
 * Authenticated-only route. If the user is not authenticated, they will be
 * redirected to the login page with the current URL as the `next` parameter.
 *
 * If n roles array is passed, then the user must at least be logged-in (not undefined)
 */
export function AuthenticatedRoute({
    children,
    roles,
}: {
    children: JSX.Element
    roles?: string[]
}): JSX.Element {
    const location = useLocation()
    const [, status] = useAuthStatus()
    const user_roles = useRoles()

    const roleSufficient = roles ? hasRole(user_roles, roles) : true

    const next = `next=${encodeURIComponent(
        location.pathname + location.search
    )}`

    if (status?.isAuthenticated && roleSufficient) {
        return children
    } else if (status?.isAuthenticated && !roleSufficient) {
        return <Navigate to="/not-found" />
    } else {
        return <Navigate to={`${URLs.LOGIN_URL}?${next}`} />
    }
}

/**
 * Anonymous-only route. If the user is authenticated, they will be
 * redirected to the login redirect URL.
 */
export function AnonymousRoute({
    children,
}: {
    children: JSX.Element
}): JSX.Element {
    const [, status] = useAuthStatus()
    if (!status?.isAuthenticated) {
        return children
    } else {
        return <Navigate to={URLs.LOGIN_REDIRECT_URL} />
    }
}

/**
 * Redirects the user to the appropriate page based on
 *  the current authentication state, including any
 * pending authentication flows. This component should
 * be placed at the top **level** of the application.
 */
export function AuthChangeRedirector({
    children,
}: {
    children: JSX.Element
}): JSX.Element {
    const [auth, event] = useAuthChange()
    const location = useLocation()
    // console.log('ACR event: ', event)
    switch (event) {
        case AuthChangeEvent.LOGGED_OUT:
            return <Navigate to={URLs.LOGOUT_REDIRECT_URL} />
        case AuthChangeEvent.LOGGED_IN:
            // First, check if user has set up 2FA. If not,
            // redirect to TOTP setup page.
            if (auth?.data?.user?.has_totp) {
                return <Navigate to={URLs.LOGIN_REDIRECT_URL} />
            }
            return <Navigate to={URLs.TOTP_SETUP_URL} />

        case AuthChangeEvent.REAUTHENTICATED: {
            const next = new URLSearchParams(location.search).get('next') || '/'
            return <Navigate to={next} />
        }
        case AuthChangeEvent.REAUTHENTICATION_REQUIRED: {
            const next = `next=${encodeURIComponent(
                location.pathname + location.search
            )}`
            const auth_resp = auth as AuthenticationResponse // this is purely for type checking
            const path = pathForFlow(auth_resp?.data.flows[0])
            return <Navigate to={`${path}?${next}`} state={{ reauth: auth }} />
        }
        case AuthChangeEvent.FLOW_UPDATED:
            const pendingFlow = navigateToPendingFlow(
                auth as AuthenticationResponse
            )
            if (!pendingFlow) {
                throw new Error()
            }
            return pendingFlow
        default:
            break
    }
    // ...stay where we are
    return children
}
