import React, { useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import NotFound from '@/routes/error/NotFound'
import HomePage from '@/modules/core/routes/Home'
// TODO: these must be split into their respective modules
import { const_routes as static_legacy_routes } from '@/modules/core/routing/const.routes'

import type { ModuleConfig } from '@/modules/core'

import { buildRoutes } from '../render'
import { useRoles, useUser } from '@/modules/auth'
import { hasRole } from '@/modules/auth/core'

const static_routes = [
    { path: '/', element: <HomePage /> },
    { path: '*', element: <NotFound /> },
]

/**
 * Returns **only** the modules that the user has access to,
 * based on the user's roles and the modules' config.
 */
function useAuthorizedModules(modules: ModuleConfig[]) {
    const user = useUser()
    const user_roles = useRoles()

    const authorized = useMemo(() => {
        return modules.filter((module) => {
            if (module.authenticated && !user) {
                return false
            }

            if (module.authRoles && !hasRole(user_roles, module.authRoles)) {
                return false
            }

            return true
        })
    }, [modules, user, user_roles])

    return authorized
}

export function Router({ modules }: { modules: ModuleConfig[] }) {
    const basename = `/${import.meta.env.VITE_FRONTEND_ROOT}` || '/app'

    const registered_modules = useAuthorizedModules(modules)

    const router = createBrowserRouter(
        buildRoutes({
            modules: registered_modules,
            errorComponent: <NotFound />,
            staticRoutes: [...static_routes, ...static_legacy_routes],
        }),
        {
            basename,
        }
    )

    return <RouterProvider router={router} />
}
