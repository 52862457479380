import React from 'react'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'
import { loader as mfaOverviewLoader } from '@/modules/auth/pages/mfa/MFAOverview'

const routes: RouteDefinition[] = [
    {
        path: 'profile',
        name: 'User Profile',
        authenticated: true,
        component: React.lazy(() => import('./pages/Profile')),
        children: [
            {
                path: 'account',
                component: React.lazy(() => import('./pages/Account')),
                children: [
                    {
                        path: 'security',
                        component: React.lazy(
                            () => import('@/modules/auth/pages/mfa/MFAOverview')
                        ),
                        loader: mfaOverviewLoader,
                    },
                ],
            },
            {
                path: 'vehicles',
                component: React.lazy(
                    () => import('@/modules/vehicles/pages/user-vehicles')
                ),
            },
            {
                path: 'sessions',
                component: React.lazy(
                    () => import('@/modules/auth/pages/usersessions/Sessions')
                ),
            },
        ],
    },
]
export const module = {
    name: 'User Space',
    routes: routes,
    basePath: 'userspace',
} as ModuleConfig

export default module
