import { useState } from 'react'
import * as allauth from '../../core/lib/allauth'
import { Navigate, useLoaderData } from 'react-router-dom'
import FormErrors from '../../components/FormErrors'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthPageWrapper } from '@/modules/auth/components/AuthPageWrapper'
import { QRCodeComponent } from '../../components/QRCode'
import { TextCopy } from '../../components/TextCopy'

import { OTPInput } from '@/modules/auth/components/OTPInput'

interface TOTPResponse {
    status: number
    meta: {
        secret: string
        totp_url: string
    }
}

export async function loader({ params }) {
    const resp = await allauth.getTOTPAuthenticator()
    return { totp: resp }
}

export default function ActivateTOTP(props) {
    const { t } = useTranslation()
    const { totp } = useLoaderData() as { totp: TOTPResponse }
    const [code, setCode] = useState('')
    const [response, setResponse] = useState({ fetching: false, content: null })

    function submit() {
        setResponse({ ...response, fetching: true })
        allauth
            .activateTOTPAuthenticator(parseInt(code))
            .then((content) => {
                setResponse((r) => {
                    return { ...r, content }
                })
            })
            .catch((e) => {
                console.error(e)
                window.alert(e)
            })
            .then(() => {
                setResponse((r) => {
                    return { ...r, fetching: false }
                })
            })
    }
    if (totp.status === 200 || response.content?.status === 200) {
        return <Navigate to="/account/settings/2fa" />
    }
    return (
        <AuthPageWrapper loading={response.fetching}>
            <h1>{t('account.mfa.activate_otp.title')}</h1>

            <p className="text-gray-600 text-sm">
                {t('account.mfa.activate_otp.authenticator_instructions')}
            </p>

            <div className="grid gap-1 mb-2">
                <label>
                    <span className="sr-only">
                        {t('account.mfa.activate_otp.authenticator_secret')}
                    </span>
                    <TextCopy
                        className="iput w-full "
                        // disabled
                        // type="text"
                        text={totp.meta.secret}
                    />
                </label>
                <p className="text-gray-400 text-xs">
                    {t('account.mfa.activate_otp.secret_storage_hint')}
                </p>
            </div>

            <QRCodeComponent value={totp.meta.totp_url} />

            <hr className="my-3" />
            <div>
                <label className="max-w-sm mx-auto">
                    {t('account.mfa.activate_otp.authenticator_code')}

                    {/* <OtpInput
                        value={code}
                        onChange={setCode}
                        numInputs={6}
                        renderSeparator={
                            <span className="flex-1 max-w-7 flex justify-center items-center">
                                -
                            </span>
                        }
                        renderInput={(props) => (
                            <div className="rounded-xl shadow h-10 w-12 flex justify-center items-center bg-gray-700">
                                <input {...props} />
                            </div>
                        )}
                        // inputType="number"
                        inputStyle="border-0 border-none bg-gray-700 text-white"
                        containerStyle="flex justify-center mt-1"
                    /> */}
                    <OTPInput value={code} onChange={setCode} numInputs={6} />
                </label>
                <FormErrors param="code" errors={response.content?.errors} />
            </div>

            <button
                className="btn btn-primary mt-2 w-full"
                onClick={() => submit()}
                disabled={response.fetching || code?.length !== 6}
            >
                {response.fetching ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                ) : null}
                {t('account.mfa.activate_otp.activate_button')}
            </button>
        </AuthPageWrapper>
    )
}
