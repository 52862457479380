import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, Navigate } from 'react-router-dom'
import { getEmailVerification, verifyEmail } from '../../core/lib/allauth'

import { AuthPageWrapper } from '@/modules/auth/components/AuthPageWrapper'

export async function loader({ params }: { params: { key: string } }) {
    const key = params.key
    const resp = await getEmailVerification(key)
    return { key, verification: resp }
}

export default function VerifyEmail() {
    const { t } = useTranslation()
    // @ts-ignore
    const { key, verification } = useLoaderData() || {}
    const [response, setResponse] = useState({ fetching: false, content: null })

    function submit() {
        setResponse({ ...response, fetching: true })
        verifyEmail(key)
            .then((content: any) => {
                setResponse((r) => {
                    return { ...r, content }
                })
            })
            .catch((e) => {
                console.error(e)
                window.alert(e)
            })
            .then(() => {
                setResponse((r) => {
                    return { ...r, fetching: false }
                })
            })
    }

    if ([200, 401].includes(response.content?.status)) {
        return <Navigate to="/login" />
    }

    if ([404].includes(response.content?.status)) {
        return <AuthPageWrapper>Invalid verification link.</AuthPageWrapper>
    }

    let body = null
    if (verification.status === 200) {
        body = (
            <>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('account.verify_email.message', {
                            email: verification.data.email,
                            user: verification.data.user.username,
                        }),
                    }}
                />
                <button
                    className="btn btn-primary"
                    disabled={response.fetching}
                    onClick={() => submit()}
                >
                    {t('account.verify_email.confirm')}
                </button>
            </>
        )
    } else if (!verification.data?.email) {
        body = <p>Invalid verification link.</p>
    } else {
        body = (
            <p
                dangerouslySetInnerHTML={{
                    __html: t('account.verify_email.already_verified', {
                        email: verification.data.email,
                    }),
                }}
            />
        )
    }
    return (
        <AuthPageWrapper>
            <h1>{t('account.verify_email.title')}</h1>
            {body}
        </AuthPageWrapper>
    )
}
