/**
 * Checks if the user has the required roles. Roles are not inclusive.
 * If the user has at least one of the required roles, it will return true.
 *
 * @param userRoles: The roles of the user.
 * @param requiredRoles: The roles required to access the resource.
 * @returns
 */
export function hasRole(userRoles: string[], requiredRoles: string[]) {
    return userRoles.some((role) => requiredRoles.includes(role));
  }
  