import { create } from 'zustand'
import { writeCookie, readCookie } from './utils'

interface DisclaimerState {
    disclaimerConsent: boolean
    setDisclaimerConsent: (consent: boolean) => void
}

// Create the Zustand store
export const useDisclaimerStore = create<DisclaimerState>((set) => ({
    disclaimerConsent: readCookie('disclaimerConsent') === 'true',
    setDisclaimerConsent: (consent: boolean) => {
        writeCookie('disclaimerConsent', consent, 365)
        set({ disclaimerConsent: consent })
    },
}))

// Optional: Create a hook for easier access to disclaimer consent
export const useDisclaimerConsent = () => {
    const { disclaimerConsent, setDisclaimerConsent } = useDisclaimerStore()
    return { disclaimerConsent, setDisclaimerConsent }
}
