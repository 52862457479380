import React from 'react'
import { LoadingScreen } from '../../components/LoadingScreen'

/**
 * Utility component to wrap around components that are loaded lazily,
 * and provide an app-wide loading screen
 */
function Suspended({ children }: { children: React.ReactNode }) {
    return (
        <React.Suspense fallback={<LoadingScreen />}>{children}</React.Suspense>
    )
}

export default Suspended
