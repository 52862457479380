import React from 'react'
import { cn } from '@/modules/core/lib/utils'

import RouteBase from '@/routes/RouteBase'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
    className?: string
    loading?: boolean
}

/**
 * Common layout for all auth pages
 * @param param0
 * @returns
 */
export function AuthPageWrapper({ children, className, loading }: Props) {
    return (
        <RouteBase
            classNames={cn(
                'relative md:flex content-center justify-center items-center h-full overflow-y-auto',
                className
            )}
        >
            <div className="relative m-0 md:m-auto flex-1 md:flex-none h-content md:h-fit min-h-full md:min-h-fit w-full md:w-auto md:max-w-xl md:rounded-xl border bg-white shadow-lg p-6 md:p-8 md:flex flex-col gap-3 ">
                {loading && (
                    <>
                        <div className="absolute top-0 left-0 right-0 h-2 bg-secondary md:rounded-t-xl animate-pulse animate--ping" />
                        <div className="absolute top-2 left-0 right-0 bottom-0 flex items-center justify-center bg-white bg-opacity-60 md:rounded-b-xl z-20"></div>
                    </>
                )}

                {children}
            </div>
        </RouteBase>
    )
}
