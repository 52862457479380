import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Navigate, useLoaderData } from 'react-router-dom'
import FormErrors from '../../components/FormErrors'

import * as allauth from '../../core/lib/allauth'

// UI
import { AuthPageWrapper } from '@/modules/auth/components/AuthPageWrapper'

export async function loader() {
    const resp = await allauth.getRecoveryCodes()
    return { recoveryCodes: resp }
}

export default function GenerateRecoveryCodes() {
    const { t } = useTranslation()
    const { recoveryCodes } = useLoaderData()
    const [response, setResponse] = useState({ fetching: false, content: null })

    const form = useForm()
    const formErrors = form.formState.errors

    function submit() {
        setResponse({ ...response, fetching: true })
        allauth
            .generateRecoveryCodes()
            .then((content) => {
                setResponse((r) => {
                    return { ...r, content }
                })
            })
            .catch((e) => {
                console.error(e)
                window.alert(e)
            })
            .then(() => {
                setResponse((r) => {
                    return { ...r, fetching: false }
                })
            })
    }
    if (response.content?.status === 200) {
        return <Navigate to="/account/2fa/recovery-codes" />
    }

    const hasCodes =
        recoveryCodes.status === 200 && recoveryCodes.data.unused_code_count > 0
    return (
        <AuthPageWrapper>
            <h1 className="text-xl">
                {t('account.mfa.recovery_codes.generate')}{' '}
            </h1>

            <form
                onSubmit={form.handleSubmit(submit)}
                className="flex flex-col gap-3"
            >
                <p>
                    {t('account.mfa.recovery_codes.generate_message')}{' '}
                    {hasCodes
                        ? t(
                              'account.mfa.recovery_codes.generate_message_has_codes'
                          )
                        : ''}{' '}
                </p>

                <FormErrors errors={response.content?.errors} />

                <button
                    disabled={response.fetching}
                    type="submit"
                    className="mt-4 btn btn-primary"
                >
                    {t('account.mfa.recovery_codes.generate_button')}
                </button>
            </form>
        </AuthPageWrapper>
    )
}
