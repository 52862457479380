/**
 *
 * These these are legacy routes which must be refactored to be exported from their respective modules
 *
 */
import React from 'react'

import Suspended from './components/Suspended'

const AboutPage = React.lazy(() => import('@/modules/core/routes/About'))
const DisclaimerPage = React.lazy(
    () => import('@/modules/core/routes/DisclaimerPage')
)

export const const_routes = [
    {
        path: 'about',
        element: (
            <Suspended>
                <AboutPage />
            </Suspended>
        ),
    },
    {
        path: 'disclaimer',
        element: (
            <Suspended>
                <DisclaimerPage />
            </Suspended>
        ),
    },
]
