import React, { useContext } from 'react'
import { DataContext } from '@/modules/core/context/DataContext'
import { useOcpiChoices } from '@/modules/core/hooks/useOcpiChoices'
import { AbstractChoicesFilter } from './AbstractFilters'
import { CapabilitiesFilter } from './CapabilitiesFilter'
import { ClearAll } from './ClearAll'
import FastCharge from './FastCharge'

import ConnectorStandard from './ConnectorStandard'
import CompanyFilter from './CompanyFilter'

import { useTranslation } from 'react-i18next'

function ParamFilters({
    onSidebar = false,
    size = 'xs',
    explainCapabilities,
    className,
    ...rest
}) {
    const { setParam, applyVehicle } = useContext(DataContext)
    const choices = useOcpiChoices()
    const { t } = useTranslation() // Initialize the translation function

    if (!choices) return null

    return (
        <>
            <CompanyFilter onSidebar={true} size={size} />


            <FastCharge
                title={t('Ταχυφόρτιση (>50kW)')}
                size={size}
                onSidebar={onSidebar}
                {...rest}
            />

            <ConnectorStandard
                title={t('Τύπος Βύσματος')}
                tooltip={t('Τύπος Βύσματος')}
                parameter="connectorType"
                choices={choices.connector_standards}
                setParam={setParam}
                size={size}
                disabled={applyVehicle}
                onSidebar={onSidebar}
                className={className}
                {...rest}
            />
            <AbstractChoicesFilter
                title={t('Σύνδεση Ρευματοδότη')}
                tooltip={t('Καλώδιο/πρίζα')}
                parameter="connectorFormat"
                choices={choices?.connector_formats}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                {...rest}
            />
            {/* <AbstractChoicesFilter
                title={t('Τύπος Ρεύματος')}
                tooltip={t('Τύπος Ρεύματος')}
                parameter="connectorPowerTypes"
                choices={choices?.connector_power_types}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                disabled={applyVehicle}
                {...rest}
            /> */}

            <AbstractChoicesFilter
                title={t('Κατάσταση')}
                tooltip={t('Κατάσταση')}
                parameter="status"
                choices={choices?.evse_statuses}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                infobox={true}
                {...rest}
            />

            <CapabilitiesFilter
                title={t('Δυνατότητες')}
                tooltip={t('Δυνατότητες')}
                parameter="evseCapabilities"
                choices={choices?.evse_capabilities}
                setParam={setParam}
                size={size}
                className={className}
                onSidebar={onSidebar}
                {...rest}
            />

            {onSidebar ? <ClearAll /> : null}
        </>
    )
}

export { ParamFilters }
