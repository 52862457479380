import { Link, useLoaderData } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as allauth from '../../core/lib/allauth'

export async function loader() {
    const resp = await allauth.getAuthenticators()
    return { authenticators: resp.data }
}

export default function MFAOverview() {
    const { t } = useTranslation()
    const { authenticators } = useLoaderData()
    const totp = authenticators.find(
        (authenticator) => authenticator.type === allauth.AuthenticatorType.TOTP
    )
    const webauthn = authenticators.filter(
        (authenticator) =>
            authenticator.type === allauth.AuthenticatorType.WEBAUTHN
    )
    const recoveryCodes = authenticators.find(
        (authenticator) =>
            authenticator.type === allauth.AuthenticatorType.RECOVERY_CODES
    )

    const ActiveDot = () => (
        <span className="w-3 h-3 rounded-full bg-green-600 shadow-inner m-1" />
    )

    return (
        <div className="h-content flex-1">
            <h1 className="text-lg font-bold mb-4 truncate">
                {t('account.mfa.overview.title')}
            </h1>

            <div className="grid gap-6 2xl:grid-cols-2">
                {/* Authenticator App Section */}
                <div className="card border bg-white shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title text-lg">
                            {totp ? <ActiveDot /> : null}
                            {t('account.mfa.overview.authenticator_app')}{' '}
                        </h2>
                        <div className="flex flex-col sm:flex-row gap-4 items-center">
                            <img
                                src="/app/img/one-time-password.png"
                                alt="one-time-password"
                                className="h-8 w-8"
                            />
                            {totp ? (
                                <p className="text-gray-500">
                                    {t('account.mfa.overview.totp_active')}
                                </p>
                            ) : (
                                <p className="text-error">
                                    {t('account.mfa.overview.totp_inactive')}
                                </p>
                            )}
                        </div>
                        {totp ? (
                            <div className="card-actions justify-end">
                                <Link
                                    to="/account/2fa/totp/deactivate"
                                    className="btn btn-outline btn-error btn-sm"
                                >
                                    {t('account.mfa.overview.deactivate')}
                                </Link>
                            </div>
                        ) : (
                            <div className="card-actions justify-end">
                                <Link
                                    to="/account/2fa/totp/activate"
                                    className="btn btn-primary"
                                >
                                    {t('account.mfa.overview.activate')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>

                {/* Security Keys Section */}
                {/* <div className="card border bg-white shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title text-lg">
                            {webauthn.length ? <ActiveDot /> : null}

                            {t('account.mfa.overview.security_keys')}
                        </h2>
                        {webauthn.length ? (
                            <>
                                <p className="text-success">
                                    {t(
                                        'account.mfa.overview.security_keys_active',
                                        {
                                            count: webauthn.length,
                                        }
                                    )}
                                </p>
                                <div className="card-actions justify-end">
                                    <Link
                                        to="/account/2fa/webauthn"
                                        className="btn btn-primary"
                                    >
                                        {t('account.mfa.overview.manage')}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="text-error">
                                    {t('account.mfa.overview.no_security_keys')}
                                </p>
                                <div className="card-actions justify-end">
                                    <Link
                                        to="/account/2fa/webauthn/add"
                                        className="btn btn-primary"
                                    >
                                        {t('account.mfa.overview.add')}
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div> */}

                {/* Recovery Codes Section */}
                <div className="card border bg-white shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title text-lg">
                            {recoveryCodes ? <ActiveDot /> : null}

                            {t('account.mfa.overview.recovery_codes')}
                        </h2>
                        <div className="flex flex-col sm:flex-row gap-4 items-center">
                            <img
                                src="/app/img/password.png"
                                alt="password-key"
                                className="h-8 w-8"
                            />
                            {!recoveryCodes ? (
                                <p className="text-error">
                                    {t(
                                        'account.mfa.overview.no_recovery_codes'
                                    )}
                                </p>
                            ) : (
                                <p className="text-gray-500">
                                    {t(
                                        'account.mfa.overview.recovery_codes_status',
                                        {
                                            unused: recoveryCodes.unused_code_count,
                                            total: recoveryCodes.total_code_count,
                                        }
                                    )}
                                </p>
                            )}
                        </div>
                        {!recoveryCodes ? (
                            <div className="card-actions justify-end">
                                <Link
                                    to="/account/2fa/recovery-codes/generate"
                                    className="btn btn-primary"
                                >
                                    {t('account.mfa.overview.generate')}
                                </Link>
                            </div>
                        ) : (
                            <div className="card-actions justify-end gap-2">
                                <Link
                                    to="/account/2fa/recovery-codes"
                                    className="btn btn-sm"
                                >
                                    {t('account.mfa.overview.view')}
                                </Link>
                                <Link
                                    to="/account/2fa/recovery-codes/generate"
                                    className="btn btn-sm btn-secondary"
                                >
                                    {t('account.mfa.overview.regenerate')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
