import React from 'react'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'

const routes: RouteDefinition[] = [
    {
        path: 'add-policy',
        component: React.lazy(
            () => import('@/modules/operators/routes/AddChargingPolicy')
        ),
        name: 'Add Charging Policy',
        authRoles: ['is_operator_staff', 'is_operator_admin'],
    },
    {
        path: 'list-policy',
        component: React.lazy(
            () => import('@/modules/operators/routes/ListChargingPolicy')
        ),
        name: 'List Charging Policy',
        authRoles: ['is_operator_staff', 'is_operator_admin'],
    },
    {
        path: 'add-policy-group',
        component: React.lazy(
            () => import('@/modules/operators/routes/AddPolicyGroup')
        ),
        name: 'Add Policy Group',
        authRoles: ['is_operator_staff', 'is_operator_admin'],
    },
    {
        path: 'list-policy-group',
        component: React.lazy(
            () => import('@/modules/operators/routes/ListPolicyGroup')
        ),
        name: 'List Policy Group',
        authRoles: ['is_operator_staff', 'is_operator_admin'],
    },
]
export const module = {
    name: 'Company Operators',
    routes: routes,
    basePath: '',
    authRoles: ['is_operator_staff', 'is_operator_admin'],
} as ModuleConfig

export default module
