import { useEffect } from 'react'

import { AuthContextProvider } from '@/modules/auth'

// analytics & cookies
import { AppCookieConsent, AnalyticsProvider } from '@/packages/analytics'

import maplibregl from 'maplibre-gl'
import { Protocol } from 'pmtiles'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Router } from '@/modules/core/routing'

const queryClient = new QueryClient()

import AuthConfig from '@/modules/auth'
import UserspaceConfig from '@/modules/users'
import DashboardConfig from '@/modules/dashboard'
import OperatorsConfig from '@/modules/operators'
import AdminConfig from '@/modules/admin'

function App() {
    useEffect(() => {
        const protocol = new Protocol()
        maplibregl.addProtocol('pmtiles', protocol.tile)
        return () => {
            maplibregl.removeProtocol('pmtiles')
        }
    }, [])

    const registeredModules = [
        AuthConfig,
        UserspaceConfig,
        DashboardConfig,
        OperatorsConfig,
        AdminConfig,
    ]

    return (
        <>
            <AnalyticsProvider>
                <QueryClientProvider client={queryClient}>
                    <AuthContextProvider>
                        <Router modules={registeredModules || []} />
                        <AppCookieConsent />
                    </AuthContextProvider>
                </QueryClientProvider>
            </AnalyticsProvider>
        </>
    )
}

export default App
