import React from 'react'
import Footer from '@/modules/core/components/Footer'
import { cn } from '@/modules/core/lib/utils'

function RouteBase({
    children,
    classNames,
    link,
    text,
    footer = false,
    ...rest
}) {
    return (
        <div
            className={cn(
                'h-full m-0 pb-[64px] pt-0 md:pt-[65px] md:pb-0 ',
                classNames
            )}
            {...rest}
        >
            {children}
            {footer ? <Footer /> : null}
        </div>
    )
}

export default RouteBase
export { RouteBase }
