import { type RouteObject, Outlet } from 'react-router-dom'
import { DataProvider } from '@/modules/core/context/DataContext'
import AnalyticsHandler from '@/packages/analytics/analytics/analytics-handler'

// Auth routing
import {
    AuthChangeRedirector,
    AnonymousRoute,
    AuthenticatedRoute,
} from '@/modules/auth'

import Suspended from '@/modules/core/routing/components/Suspended'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'

import PageLayout from '@/modules/core/layout/PageLayout'

const renderRoute = (
    routes: RouteDefinition[]
    // roles: string[]
): RouteObject[] => {
    return (
        routes?.map((route) => {
            const {
                path,
                component: Component,
                children,
                loader,
                authenticated,
                anonymousOnly,
                authRoles,
            } = route

            const enforceAuth =
                authenticated || (authRoles && authRoles.length > 0)

            const BaseComponent = Component ? Component : () => <Outlet />

            // enforce attribute authenticated
            const ProtectedComponent = enforceAuth
                ? () => (
                      <AuthenticatedRoute roles={authRoles}>
                          <BaseComponent />
                      </AuthenticatedRoute>
                  )
                : () => <BaseComponent />

            // enforce attribute anonymousOnly
            const AnonymousOnlyComponent = anonymousOnly
                ? () => (
                      <AnonymousRoute>
                          <ProtectedComponent />
                      </AnonymousRoute>
                  )
                : () => <ProtectedComponent />

            // Check if component is instance of React.Lazy
            const lazy = Component?.$$typeof === Symbol.for('react.lazy')
            const element = lazy ? (
                <Suspended>
                    <AnonymousOnlyComponent />
                </Suspended>
            ) : (
                <AnonymousOnlyComponent />
            )

            const shouldRenderChildren = true
            // !enforceAuth || hasRole(roles, authRoles || []);

            return {
                path,
                element,
                loader,
                children:
                    children && shouldRenderChildren
                        ? renderRoute(children)
                        : undefined,
            }
        }) || []
    )
}

export const buildRoutes = ({
    modules,
    staticRoutes,
    errorComponent,
}: {
    modules: ModuleConfig[]
    staticRoutes?: RouteObject[]
    errorComponent?: React.ReactNode
}): RouteObject[] => {
    const moduleRoutes =
        modules?.map((module) => ({
            path: module.basePath,
            children: renderRoute(module.routes),
        })) || []

    return [
        {
            path: '/',
            element: (
                <AnalyticsHandler>
                    <DataProvider>
                        <AuthChangeRedirector>
                            <PageLayout />
                        </AuthChangeRedirector>
                    </DataProvider>
                </AnalyticsHandler>
            ),
            errorElement: errorComponent || undefined,
            children: [
                ...(staticRoutes || []), // legacy route defs, TODO: export them from modules
                ...moduleRoutes,
            ],
        },
    ]
}
