import QRCode from 'react-qr-code'

/**
 * Renders a QR Code encoding a string
 */
export const QRCodeComponent = ({ value }: { value: string }) => {
    return (
        <div
            style={{
                height: 'auto',
                margin: '0 auto',
                minWidth: 130,
                maxWidth: 250,
                width: '100%',
            }}
            className="bg-white rounded-xl shadow border p-5 md:p-8 m-2"
        >
            <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={value}
                viewBox={`0 0 256 256`}
            />
        </div>
    )
}
