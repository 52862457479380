import { useTranslation } from 'react-i18next'
import NavLinkElement from './NavLinkElement'
import { useUser } from '@/modules/auth/core'

function PrivilegedUserLinks() {
    const { t } = useTranslation()
    const user = useUser()

    return (
        <>
            {user?.is_operator_staff && (
                <>
                    <NavLinkElement
                        to="/list-policy"
                        data-tip={t('Πολιτικές Τιμολόγησης')}
                    >
                        <div className="hidden 2xl:inline ml-1">
                            {t('Πολιτικές Τιμολόγησης')}
                        </div>
                    </NavLinkElement>

                    <NavLinkElement
                        to="/list-policy-group"
                        data-tip={t('Ομάδες Φορτιστών')}
                    >
                        <div className="hidden 2xl:inline ml-1">
                            {t('Ομάδες Φορτιστών')}
                        </div>
                    </NavLinkElement>
                </>
            )}
            {user?.is_rae_admin ||
                (user?.is_superuser && (
                    <>
                        <NavLinkElement
                            to="/statistics"
                            data-tip={t('Στατιστικά')}
                        >
                            {t('Στατιστικά')}
                        </NavLinkElement>
                    </>
                ))}
        </>
    )
}

function RegularUserLinks() {
    const { t } = useTranslation()
    const user = useUser()

    return (
        <>
            {user ? (
                <>
                    <NavLinkElement to="/dashboard">
                        <div className="">
                            <div className="">{t('comparison_page')}</div>
                        </div>
                    </NavLinkElement>
                    <NavLinkElement to="/userspace/profile">
                        <div className="">
                            <div className="">{t('account.profile')}</div>
                        </div>
                    </NavLinkElement>
                </>
            ) : null}
        </>
    )
}

function NavLinks() {
    return (
        <div className="flex gap-2 rounded-xl  mr-3">
            <RegularUserLinks />
            <PrivilegedUserLinks />
        </div>
    )
}

export default NavLinks
