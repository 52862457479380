import { useMemo } from 'react'
import { getCSRFToken } from '@/modules/auth'
import { CharginCostAPI } from '@/api'

export const useApi = () => {
    const csrf = getCSRFToken()

    const api = useMemo(() => {
        const instance = new CharginCostAPI({
            BASE: `${window.location.origin}${
                import.meta?.env?.VITE_BACKEND_ROOT
                    ? import.meta?.env?.VITE_BACKEND_ROOT
                    : ''
            }`,
        })

        instance.request.config.CREDENTIALS = 'same-origin'
        instance.request.config.HEADERS = {
            'Content-Type': 'application/json',
        }
        instance.request.config.HEADERS['X-CSRFToken'] = csrf || ''

        return instance
    }, [csrf])

    return api
}
