import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '@/modules/core/context/DataContext'
import { useUser } from '@/modules/auth'
import { useUserPosition } from '@/packages/position/context'

import Logo from '@/modules/core/components/Logo'
import LogoImg from '@/assets/logo_45.webp'

import { LocationFilter } from '@/packages/cc-filters/components/LocationFilter'
import { UserVehiclesWidget } from '@/modules/vehicles'
import { UserPosition } from '@/packages/position/components/UserPosition'
import { ParamFilters } from '@/packages/cc-filters/components/ParamFilters'
import { ChargeAmount } from '@/packages/cc-filters/components/ChargeAmount'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSpinner,
    faRoute,
    faCar,
    faCrosshairs,
    faFilter,
    faBolt,
    faChargingStation,
} from '@fortawesome/free-solid-svg-icons'
// import Example from '../filters/Test'

function SidebarItem({
    minimizedContent,
    tooltip,
    icon,
    expanded,
    children,
    minimizedClassNames,
    ...rest
}) {
    const { t } = useTranslation()

    if (expanded) {
        return <>{children}</>
    }

    // Explicit minimized content (e.g. for Logo)
    if (minimizedContent) {
        return (
            <div
                className={
                    'cursor-pointer tooltip tooltip-right p-1 hover:opacity-90 transition-all ' +
                        minimizedClassNames || ''
                }
                data-tip={t(tooltip)}
            >
                {minimizedContent}
            </div>
        )
    }

    // Default minimized content with icon
    return (
        <>
            <ul className="menu menu-sm rounded-box ">
                <li tabIndex={0}>
                    <span className="hover:bg-primary ">
                        {' '}
                        <FontAwesomeIcon icon={icon} />
                    </span>
                    <ul className="rounded-box p-2 min-w-[300px]">
                        {children}
                    </ul>
                </li>
            </ul>
        </>
    )
}
function LogoItem({ expanded }) {
    const minimizedContent = () => {
        return (
            <Logo link="/" overwriteChildren className="group">
                <img
                    src={LogoImg}
                    alt="Charging Cost Logo"
                    className="w-14 inline transition-all "
                />
            </Logo>
        )
    }

    const expandedContent = () => {
        return <Logo link="/" />
    }

    return (
        <SidebarItem
            minimizedContent={minimizedContent()}
            tooltip={'Home'}
            expanded={expanded}
            minimizedClassNames="p-1"
        >
            {expandedContent()}
        </SidebarItem>
    )
}
function FeaturesCount({ expanded }) {
    const { t } = useTranslation()
    const { data, dataLoading } = useContext(DataContext)

    const minimizedContent = () => {
        return (
            <span
                className={`text-xs mask mask-circle bg-gray-100 text-black p-2  ${
                    dataLoading && 'animate-bg-color'
                }`}
            >
                {dataLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                ) : data?.features.length ? (
                    `${data.features.length} `
                ) : (
                    '0 '
                )}
            </span>
        )
    }

    const expandedContent = () => {
        if (dataLoading)
            return (
                <div className="flex flex-row items-center gap-1 px-1">
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <span className="text-xs">{t('Αναζήτηση')}...</span>
                </div>
            )
        return (
            <div className="flex flex-row items-center gap-1 px-1 text-xs">
                <FontAwesomeIcon icon={faChargingStation} className="" />
                <span className="font-bold">
                    {data?.features.length ? `${data.features.length} ` : '0 '}
                </span>
                <span>{!dataLoading ? ` ${t('σταθμοί φόρτισης')}` : ''}</span>
            </div>
        )
    }

    return (
        <SidebarItem
            minimizedContent={minimizedContent()}
            tooltip="Αριθμός σταθμών"
            expanded={expanded}
        >
            {expandedContent()}
        </SidebarItem>
    )
}
function DistanceFilter({ expanded }) {
    const { userPosition } = useUserPosition()

    if (!userPosition) return null

    return (
        <SidebarItem
            tooltip="Απόσταση από εμένα"
            expanded={expanded}
            icon={faRoute}
        >
            <LocationFilter className="bg-primary text-white p-2 rounded-lg" />
        </SidebarItem>
    )
}
function SelectVehicle({ expanded }) {
    const user = useUser()

    if (!user) return null // Unauthenticated
    return (
        <SidebarItem
            tooltip="Επιλεγμένο όχημα"
            expanded={expanded}
            icon={faCar}
        >
            <div className="bg-primary rounded-lg py-1 px-3 flex flex-col items-start text-sm max-w-[100%]">
                <UserVehiclesWidget />
            </div>
        </SidebarItem>
    )
}
function UserLocation({ expanded }) {
    return (
        <SidebarItem
            tooltip="Η τοποθεσία μου"
            expanded={expanded}
            icon={faCrosshairs}
        >
            <div className="bg-primary rounded-lg py-1 px-2 flex flex-col items-start text-sm font-bold">
                <UserPosition
                    expanded={expanded}
                    className="w-full mr-2 p-1 rounded-lg transition-all "
                />
            </div>
        </SidebarItem>
    )
}

function OtherFilters({ expanded }) {
    const { t } = useTranslation()

    return (
        <>
            <SidebarItem tooltip="Φίλτρα" expanded={expanded} icon={faFilter}>
                <>
                    {/* <Example /> */}

                    <div className="relative z-0">
                        <div
                            className="collapse collapse-arrow rounded-lg bg-primary"
                            style={{
                                visibility: expanded ? 'visible' : 'inherit',
                            }}
                        >
                            <input type="checkbox" defaultChecked />
                            <div className="collapse-title text-sm font-medium">
                                {t('Φίλτρα')}
                            </div>
                            <div className="collapse-content overflow-y-auto">
                                <div className="flex flex-col w-content m-auto ">
                                    <ParamFilters onSidebar={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </SidebarItem>
        </>
    )
}

function ChargeAmountFilters({ expanded }) {
    return (
        <SidebarItem tooltip="Ποσό φόρτισης" expanded={expanded} icon={faBolt}>
            <div className="bg-primary rounded-lg py-1 px-2 flex flex-col items-start text-sm font-bold">
                <ChargeAmount />
            </div>
        </SidebarItem>
    )
}

export {
    SidebarItem,
    FeaturesCount,
    DistanceFilter,
    LogoItem,
    SelectVehicle,
    UserLocation,
    OtherFilters,
    ChargeAmountFilters,
}
