/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CustomUser } from '../models/CustomUser';
import type { WhoAmI } from '../models/WhoAmI';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesList(): CancelablePromise<Array<Company>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/',
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesCreate({
data,
}: {
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/companies/',
            body: data,
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesRead({
id,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns Company 
     * @throws ApiError
     */
    public authCompaniesPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
data: Company,
}): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authCompaniesDelete({
id,
}: {
/**
 * A unique integer value identifying this Εταιρεία.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/companies/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authTerminateAccountDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/terminate-account/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public authUserProfileUpdate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authUserProfileDelete(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/user-profile/',
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersList(): CancelablePromise<Array<CustomUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/',
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersCreate({
data,
}: {
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/users/',
            body: data,
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersRead({
id,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns CustomUser 
     * @throws ApiError
     */
    public authUsersPartialUpdate({
id,
data,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
data: CustomUser,
}): CancelablePromise<CustomUser> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public authUsersDelete({
id,
}: {
/**
 * A unique integer value identifying this Χρήστης.
 */
id: number,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns WhoAmI 
     * @throws ApiError
     */
    public authWhoamiList(): CancelablePromise<WhoAmI> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/whoami/',
        });
    }

}
