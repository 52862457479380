import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'react-daisyui'
import { DataContext } from '@/modules/core/context/DataContext'

import { useUserVehicles } from '../hooks'
import { getConnectorType } from '../lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export function NoVehicles() {
    const { t } = useTranslation()
    return (
        <Link
            to="/userspace/profile/vehicles?section=vehicles&add_vehicle=true"
            className="p-2 flex flex-col gap-1 w-full border border-dashed rounded-xl my-2"
        >
            <h3>
                <FontAwesomeIcon icon={faPlus} className="mr-2 " />
                {t('vehicles.title')}
            </h3>
            <p className="text-xs text-gray-400">
                {t('vehicles.no_vehicles_sidebar_placeholder')}
            </p>
        </Link>
    )
}

export function UserVehiclesWidget() {
    const { t } = useTranslation()
    const { data: vehicles } = useUserVehicles()
    const [selectedVehicle, setSelectedVehicle] = React.useState<
        string | undefined
    >(
        vehicles && vehicles.length > 0
            ? vehicles?.find((v) => v.is_primary)?.properties.uuid ||
                  vehicles[0].properties.uuid
            : undefined
    )

    const { setParamsMulti, applyVehicle, setApplyVehicle, params } =
        useContext(DataContext)

    /**
     * Populate the vehicle specs filter
     * based on the selected vehicle
     */
    const setFilter = (vuuid: string | undefined, fast_charge: boolean) => {
        if (!vuuid) return
        const vehicle = vehicles?.find((v) => v.properties.uuid === vuuid)
        if (!vehicle) return

        // Invalid parameters: fast charge selected but vehicle doesn't support it
        if (fast_charge && !!!vehicle.properties.fastcharge_plug) {
            window.alert(
                "This vehicle doesn't have a fast charge plug. Please select a different vehicle, or disable fast charge option."
            )
            setParamsMulti([
                {
                    name: 'fastCharge',
                    value: false,
                },
            ])
            return
        }

        const plug = fast_charge
            ? vehicle.properties.fastcharge_plug
            : vehicle.properties.charge_plug

        const newParams = [
            {
                name: 'connectorType',
                value: getConnectorType({
                    charge_plug: plug,
                }),
            },
            // Removed param 'electricPower': onboard charger only supports or limits the
            // the power provided by the EVSE. It is not an excluding
            // factor for the EVSE selection.
        ]

        setParamsMulti(newParams)
    }

    /**
     * Clear the vehicle specs filter
     */
    const clearFilter = () => {
        setParamsMulti([
            {
                name: 'connectorType',
                value: null,
            },
        ])
    }

    useEffect(() => {
        if (applyVehicle) {
            setFilter(selectedVehicle, params['fastCharge'])
        } else {
            clearFilter()
        }
    }, [applyVehicle, selectedVehicle, params['fastCharge']])

    if (!vehicles || vehicles?.length === 0) return <NoVehicles />

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="form-control">
                <label className="label cursor-pointer">
                    <span className="ml-1 text-xs text-left font-normal">
                        {t('Φιλτράρισμα βάσει του οχήματος μου')}
                    </span>

                    <input
                        type="checkbox"
                        checked={applyVehicle}
                        onChange={() => setApplyVehicle(!applyVehicle)}
                        className="checkbox checkbox-xs checkbox-success"
                    />
                </label>
            </div>
            <Select
                size="xs"
                className="select-sm m-1 w-full text-gray-700"
                value={selectedVehicle}
                onChange={(e) => setSelectedVehicle(e.target.value)}
            >
                {vehicles?.map((vehicle) => (
                    <option
                        key={vehicle.properties.uuid}
                        value={vehicle.properties.uuid}
                    >
                        {vehicle.properties?.vehicle_make}{' '}
                        {vehicle.properties?.vehicle_model}{' '}
                    </option>
                ))}
            </Select>
        </div>
    )
}
