import React from 'react'
import type { ModuleConfig, RouteDefinition } from '@/modules/core'

import { loader as verifyEmailLoader } from './pages/account/VerifyEmail'
import { loader as activateTOTPLoader } from './pages/mfa/ActivateTOTP'
import { loader as mfaOverviewLoader } from './pages/mfa/MFAOverview'
import { loader as recoveryCodesLoader } from './pages/mfa/RecoveryCodes'
import { loader as generateRecoveryCodesLoader } from './pages/mfa/GenerateRecoveryCodes'
import { loader as resetPasswordLoader } from './pages/account/ResetPassword'

const routes: RouteDefinition[] = [
    {
        path: 'signup',
        component: React.lazy(() => import('./pages/account/Signup')),
        name: 'Signup',
        anonymousOnly: true,
    },
    {
        path: 'login',
        component: React.lazy(() => import('./pages/account/Login')),
        name: 'Login',
        anonymousOnly: true,
    },
    {
        path: 'logout',
        component: React.lazy(() => import('./pages/account/Logout')),
        name: 'Logout',
        authenticated: true,
    },
    {
        path: 'authenticate',

        // component: Outlet,
        name: 'Authenticate',
        anonymousOnly: true,
        children: [
            {
                path: 'totp',
                component: React.lazy(
                    () => import('./pages/mfa/AuthenticateTOTP')
                ),
            },
            {
                path: 'recovery-codes',
                component: React.lazy(
                    () => import('./pages/mfa/AuthenticateRecoveryCodes')
                ),
            },
        ],
    },
    {
        path: 'reauthenticate',
        // component: Outlet,
        name: 'Reauthenticate',
        authenticated: true,
        children: [
            {
                path: '',
                component: React.lazy(
                    () => import('./pages/account/Reauthenticate')
                ),
                name: 'Reauthenticate',
                authenticated: true,
            },
            {
                path: 'totp',
                component: React.lazy(
                    () => import('./pages/mfa/ReauthenticateTOTP')
                ),
                name: 'Reauthenticate TOTP',
                authenticated: true,
            },
        ],
    },
    {
        path: 'password',
        children: [
            {
                path: 'change',
                component: React.lazy(
                    () => import('./pages/account/ChangePassword')
                ),
            },
            {
                path: 'reset',
                component: React.lazy(
                    () => import('./pages/account/RequestPasswordReset')
                ),
            },
            {
                path: 'reset/key/:key',
                component: React.lazy(
                    () => import('./pages/account/ResetPassword')
                ),
                loader: resetPasswordLoader,
            },
        ],
    },
    {
        path: 'verify-email',
        component: React.lazy(
            () => import('./pages/account/VerificationEmailSent')
        ),
        anonymousOnly: true,
    },
    {
        path: 'verify-email/:key',
        component: React.lazy(() => import('./pages/account/VerifyEmail')),
        name: 'Applications',
        loader: verifyEmailLoader,
        anonymousOnly: true,
    },
    {
        path: '2fa',
        // component: Outlet,
        children: [
            {
                path: 'totp/activate',
                component: React.lazy(() => import('./pages/mfa/ActivateTOTP')),
                name: 'Activate TOTP',
                loader: activateTOTPLoader,
                authenticated: true,
            },
            {
                path: 'totp/deactivate',
                component: React.lazy(
                    () => import('./pages/mfa/DeactivateTOTP')
                ),
                name: 'Activate TOTP',
                // loader: activateTOTPLoader,
                authenticated: true,
            },
            {
                path: 'recovery-codes',
                component: React.lazy(
                    () => import('./pages/mfa/RecoveryCodes')
                ),
                name: 'Activate RecoveryCodes',
                loader: recoveryCodesLoader,
                authenticated: true,
            },
            {
                path: 'recovery-codes/generate',
                component: React.lazy(
                    () => import('./pages/mfa/GenerateRecoveryCodes')
                ),
                name: 'Activate RecoveryCodes',
                loader: generateRecoveryCodesLoader,
                authenticated: true,
            },
        ],
    },
    {
        path: 'provider/callback',
        component: React.lazy(
            () => import('./pages/socialaccount/ProviderCallback')
        ),
    },
    {
        path: 'settings',
        name: 'Settings',
        authenticated: true,
        component: React.lazy(() => import('./pages/Settings')),
        children: [
            {
                path: '',
                // component: Fragment,
                name: 'Account',
            },
            {
                path: '2fa',
                component: React.lazy(() => import('./pages/mfa/MFAOverview')),
                loader: mfaOverviewLoader,
                name: '2FA',
            },
            {
                path: 'sessions',
                component: React.lazy(
                    () => import('./pages/usersessions/Sessions')
                ),
                name: '2FA',
            },
        ],
    },
]
export const module = {
    name: 'Account',
    routes: routes,
    basePath: 'account',
} as ModuleConfig

export default module
